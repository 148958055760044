import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { PARTMER_API, CONTACT_API, BaseGatewayImage } from '@app/utils/apiUrl';
import { DeleteSingle, Get,Post } from '@app/utils/httpProvider';
import { createUrlWithParam } from '../../utils/helper';
import { URI_INTERNAL } from '../../utils/pathLocations';
import { objectShowNumber, messageToast } from '../../utils/define';
import { ConfirmModal } from '../../modules/modal/ConfirmModal';
import * as moment from 'moment';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';

const Contact = () => {
    const history = useHistory();
    const location = useLocation();
    const valueSearch = useSelector((state) => state.search.valueSearch);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [search, setSearch] = useState('');

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [idWantDelete, setIdWantDelete] = useState('');
    const [confirmDelMessage, setConfirmDelMessage] = useState('');



    const paramUrl = new URLSearchParams(location.search.substring(1));
    if (
        paramUrl.get('page') != null &&
        parseInt(paramUrl.get('page'), 10) !== page
    ) {
        const pageResult = parseInt(paramUrl.get('page'), 10);
        setPage(pageResult);
    }
    if (
        paramUrl.get('size') != null &&
        parseInt(paramUrl.get('size'), 10) !== pageSize
    ) {
        const sizeResult = parseInt(paramUrl.get('size'), 10);
        setPageSize(sizeResult);
    }
    if (paramUrl.get('search') != null && paramUrl.get('search') !== search) {
        setSearch(paramUrl.get('search'));
    }

    const { isLoading, isError, error, data, refetch } = useQuery(
        ['contactData', valueSearch, page, pageSize],
        () =>
            Get(CONTACT_API.GET_PAGING_CONTACT, null, {
                Keyword: valueSearch ? valueSearch : '',
                PageIndex: page,
                PageSize: pageSize
            })
    );

    useEffect(() => {
        refetch();
    }, [pageSize, page]);

    const replaceHistory = async (Page, Size, Search) => {
        history.replace(
            createUrlWithParam(URI_INTERNAL.CONTACT_PATH, {
                page: Page,
                size: Size,
                search: Search
            })
        );
    };

    React.useEffect(() => {
        if (valueSearch != null && valueSearch !== search) {
            replaceHistory(1, pageSize, valueSearch);
        }
    }, [valueSearch]);

    const changePage = (number) => {
        setPage(number);
        replaceHistory(number, pageSize, search);
    };

    const onChangeShowNumber = (e) => {
        setPageSize(e.target.value);
        replaceHistory(page, e.target.value, search);
    };

    const changeStatusFunc = useMutation((id) =>
        Post(CONTACT_API.CHANGE_STATUS_CONTACT, id)
    );

    const changeStatusError = (id) => {
        changeStatusFunc.mutate(id, {
            onSuccess: (res) => {
                if (res.status === 201 || res.status === 200) {
                    refetch();
                    toast.success(messageToast.messageToastUpdateSuccess);
                } else {
                    toast.error(messageToast.messageToastUpdateError);
                }
            },
            onError: (res) => {
                toast.error(messageToast.messageToastTryCatch);
            }
        });
        replaceHistory(page, pageSize, search);
    }


    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title"> Danh sách phản hồi của người dùng</h3>
                        </div>
                        <div className="card-body">
                            <div className="d-flex justify-content-between">
                                <form className="form-inline">
                                    <label
                                        htmlFor="exampleEmail2"
                                        className="mr-2"
                                    >
                                        Hiển thị :
                                    </label>
                                    <select
                                        className="form-control"
                                        value={pageSize}
                                        onChange={(e) => onChangeShowNumber(e)}
                                    >
                                        {objectShowNumber.map((item, key) => (
                                            <option
                                                key={Number(key)}
                                                value={item.value}
                                            >
                                                {item.text}
                                            </option>
                                        ))}
                                    </select>
                                </form>
                            </div>
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : isError ? (
                                <div>
                                    An error has occurred: ${error.message}
                                </div>
                            ) : (
                                <div
                                    id="example2_wrapper"
                                    className="dataTables_wrapper dt-bootstrap4"
                                >
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Họ tên
                                                        </th>
                                                        <th className="sorting">
                                                            Email
                                                        </th>
                                                        <th className="sorting">
                                                            Số điện thoại
                                                        </th>
                                                        <th className="sorting">
                                                            Nội dung
                                                        </th>
                                                        {/* <th className="sorting">
                                                            Ngày đặt bàn
                                                        </th>
                                                        <th className="sorting">
                                                            Thời gian
                                                        </th>
                                                        <th className="sorting">
                                                            Số lượng khách
                                                        </th> */}
                                                        <th className="sorting">
                                                            Đã liên hệ lại
                                                        </th>

                                                    </tr>
                                                </thead>
                                                {data.data.$values != null &&
                                                    data.data.$values !==
                                                    undefined &&
                                                    data.data.$values.length > 0 ? (
                                                    <tbody>
                                                        {data.data.$values.map(
                                                            (item, index) => (
                                                                <tr
                                                                    key={Number(
                                                                        index
                                                                    )}
                                                                    style={{background:item.isContact ? "transparent" : 'red'}}
                                                                    className="odd"
                                                                >
                                                                    <td className="dtr-control sorting_1">
                                                                        {index +
                                                                            1 +
                                                                            (page -
                                                                                1) *
                                                                            pageSize}
                                                                    </td>
                                                                    <td>
                                                                        {item.fullName}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.email
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.phoneNumber
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.content
                                                                        }
                                                                    </td>
                                                                    {/* <td>
                                                                        {moment(
                                                                            item.dateOrder
                                                                        ).format('DD/MM/YYYY')}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.time
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            item.number
                                                                        }
                                                                    </td> */}
                                                                    <td>
                                                                        <input type="checkbox" onClick={() => changeStatusError(item.id)} defaultChecked={item.isContact} name="vehicle1" value="Bike" />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr className="txt-center">
                                                            <td colSpan="12">
                                                                Không có dữ liệu
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                <tfoot>
                                                    <tr role="row">
                                                        <th className="sorting">
                                                            STT
                                                        </th>
                                                        <th className="sorting">
                                                            Họ tên
                                                        </th>
                                                        <th className="sorting">
                                                            Email
                                                        </th>
                                                        <th className="sorting">
                                                            Số điện thoại
                                                        </th>
                                                        <th className="sorting">
                                                            Nội dung
                                                        </th>
                                                        {/* <th className="sorting">
                                                            Ngày đặt bàn
                                                        </th>
                                                        <th className="sorting">
                                                            Thời gian
                                                        </th>
                                                        <th className="sorting">
                                                            Số lượng khách
                                                        </th> */}
                                                        <th className="sorting">
                                                            Đã liên hệ lại
                                                        </th>

                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div
                                                className="dataTables_paginate paging_simple_numbers"
                                                id="example2_paginate"
                                            >
                                                <Pagination
                                                    itemClass="page-item"
                                                    linkClass="page-link"
                                                    innerClass="pagination pagination-sm no-margin pull-right"
                                                    activePage={page}
                                                    itemsCountPerPage={Number(
                                                        pageSize
                                                    )}
                                                    totalItemsCount={
                                                        data.totalRecords
                                                    }
                                                    pageRangeDisplayed={5}
                                                    onChange={(number) =>
                                                        changePage(number)
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
