import React, { useEffect, useState } from 'react';
import { Button } from '@components';
import { Card, Form, Row, Col, ButtonGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useLocation, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputFile from '@app/components/input-file/InputFile';
import { Get, Put } from '@app/utils/httpProvider';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import DatePicker from 'react-datepicker';
import Editor from '@app/components/editor/Editor';
import Select from 'react-select';
import SelectTree from '@app/components/select-tree/SelectTree';
import { NIL as NIL_UUID } from 'uuid';
import {
    messageError,
    messageToast,
    acceptImage,
    objectStatusPost,
    styleSelectDefault,
    styleSelectError,
    status200,
    dataTypeCategory,
    DATE_TIME_REACT_FORMAT,
    role,
    postStatus
} from '@app/utils/define';
import { BaseGatewayImage, POST_API, CATEGORY_API } from '@app/utils/apiUrl';
import ReactSpinner from '@app/components/reactSpinner/ReactSpinner';
import { dateTimeToUTCRequest, getInfomationAccountFromToken, isValidUrl } from '@app/utils/helper';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DocumentInput from '@app/components/document-input/DocumentInput';

const schema = yup.object().shape({
    title: yup.string().required(messageError.emptyMessage),
    imageThumbnail: yup.string().required(messageError.emptyMessage),
    // postStatus: yup.object().required(messageError.emptyMessage)
});

const PostEdit = () => {
    const history = useHistory();
    const queryClient = useQueryClient();
    const location = useLocation();
    const [isLoadingEdit, setIsLoadingEdit] = useState(false);
    // const [publishTime, setPublishTime] = useState(new Date());
    const paramUrl = new URLSearchParams(location.search.substring(1));
    const id = paramUrl.get('id');

    const postItem = useQuery([id], () => Get(POST_API.GET_ITEM_POST, id));

    const listCate = useQuery(['getAllCate'], () =>
        Get(CATEGORY_API.GET_ALL_CATEGORY)
    );

    const editFunc = useMutation((value) =>
        Put(POST_API.UPDATE_POST, value.id, value.data)
    );

    let objectStatusFilter;
    const inforAccount = getInfomationAccountFromToken();
    if (inforAccount && inforAccount.role) {
        const userRole = inforAccount.role.split(', ');
        if (userRole.includes(role.Admin) || userRole.includes(role.Manager) || userRole.includes(role.Publisher)) {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all);
        } else {
            objectStatusFilter = objectStatusPost.filter(x => x.value !== postStatus.all && x.value !== postStatus.release);
        }
    }

    const handleEdit = async (values) => {
        const model = { ...values };
        model.categoryId =
            values.categoryId === 'null' ? '' : values.categoryId;
        model.publishTime = dateTimeToUTCRequest(values.publishTime);
        // model.status = values.postStatus.value;
        model.status = selectedOptions?.value;
        model.dataType = listCate.data.data.$values.find(
            (x) => x.id === values.categoryId
        ).dataType;
        const modelUpdate = {
            data: model,
            id: id
        };
        editFunc.mutate(modelUpdate, {
            onSuccess: (res) => {
                values.publishTime.setHours(values.publishTime.getHours() - 7);
                if (res.status !== status200) {
                    toast.error(messageToast.messageToastUpdateError);
                } else {
                    setIsLoadingEdit(true);
                    queryClient.setQueryData(id, model);
                    toast.success(messageToast.messageToastUpdateSuccess);
                    history.goBack();
                }
            },
            onError: (res) => {
                toast.error('Đã có lỗi xảy ra, vui lòng thử lại !');
            }
        });
    };

    const isLoading = postItem.isLoading || listCate.isLoading;
    const isError = postItem.isError || listCate.isError;
    const error = postItem.isError ? postItem.error : listCate.error;

    let menuData = [];
    if (!listCate.isLoading && !listCate.isError) {
        menuData = listCate.data
            ? listCate.data.data.$values.filter(
                (x) => x.dataType === dataTypeCategory.information
            )
            : [];
    }

    const [selectedOptions, setSelectedOptions] = useState({});
    const [isManuallySelected, setIsManuallySelected] = useState(false);
    useEffect(() => {
        if (!isManuallySelected && postItem && postItem.data && objectStatusFilter?.length > 0) {
            const foundOption = objectStatusFilter.find(x => x.value === postItem.data.status);
            setSelectedOptions(foundOption || {});
        }
    }, [postItem, isManuallySelected, objectStatusFilter]);


    const handleButtonClick = (option) => {
        if (objectStatusFilter.includes(option)) {
            setSelectedOptions(option);
            setIsManuallySelected(true); 
        }
    };

    return (
        <Card>
            <Card.Header as="h5">Sửa thông tin tin tức bài viết</Card.Header>
            <Card.Body>
                {isLoading ? (
                    <div>Loading...</div>
                ) : isError ? (
                    <div>An error has occurred: ${error.message}</div>
                ) : postItem.data == null ? (
                    <div>Tin tức không tồn tại</div>
                ) : (
                    <Formik
                        enableReinitialize
                        validationSchema={schema}
                        onSubmit={(values) => handleEdit(values)}
                        initialValues={{
                            title:
                                postItem.data && postItem.data.title
                                    ? postItem.data.title
                                    : '',
                            titleEn:
                                postItem.data && postItem.data.titleEn
                                    ? postItem.data.titleEn
                                    : '',
                            description:
                                postItem.data && postItem.data.description
                                    ? postItem.data.description
                                    : '',
                            descriptionEn:
                                postItem.data && postItem.data.descriptionEn
                                    ? postItem.data.descriptionEn
                                    : '',
                            keywords:
                                postItem.data && postItem.data.keywords
                                    ? postItem.data.keywords
                                    : '',
                            documentLink: postItem.data && postItem.data.documentLink
                                ? postItem.data.documentLink
                                : '',
                            ogTitle:
                                postItem.data && postItem.data.ogTitle
                                    ? postItem.data.ogTitle
                                    : '',
                            ogDescription:
                                postItem.data && postItem.data.ogDescription
                                    ? postItem.data.ogDescription
                                    : '',
                            articleTag:
                                postItem.data && postItem.data.articleTag
                                    ? postItem.data.articleTag
                                    : '',
                            content:
                                postItem.data && postItem.data.content
                                    ? postItem.data.content
                                    : '',
                            contentEn:
                                postItem.data && postItem.data.contentEn
                                    ? postItem.data.contentEn
                                    : '',
                            videoLink:
                                postItem.data && postItem.data.videoLink
                                    ? postItem.data.videoLink
                                    : '',
                            isFocus:
                                postItem.data && postItem.data.isFocus
                                    ? postItem.data.isFocus
                                    : false,
                            isHighlight: postItem.data && postItem.data.isHighlight
                                ? postItem.data.isHighlight
                                : false,
                            author: postItem.data && postItem.data.author
                                ? postItem.data.author
                                : '',
                            imagePath:
                                postItem.data && postItem.data.imagePath
                                    ? postItem.data.imagePath
                                    : '',
                            imageThumbnail:
                                postItem.data && postItem.data.imageThumbnail
                                    ? postItem.data.imageThumbnail
                                    : '',
                            publishTime:
                                postItem.data && postItem.data.publishTime
                                    ? new Date(postItem.data.publishTime)
                                    : new Date(),

                            // postStatus: postItem.data
                            //     ? objectStatusPost.find(
                            //         (x) => x.value === postItem.data.status
                            //     )
                            //     : '',


                            virtualViews: postItem.data && postItem.data.virtualViews
                                ? postItem.data.virtualViews
                                : 0,
                            categoryId:
                                postItem.data.categoryId === NIL_UUID
                                    ? 'null'
                                    : postItem.data.categoryId,
                            sourceLink: postItem.data && postItem.data.sourceLink
                                ? postItem.data.sourceLink
                                : '',
                            isGoogleForm: postItem.data && postItem.data.isGoogleForm
                                ? postItem.data.isGoogleForm
                                : false,
                            contentGoogleForm: postItem.data && postItem.data.contentGoogleForm
                                ? postItem.data.contentGoogleForm
                                : '',
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            errors,
                            setFieldValue
                        }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col className="text-end">
                                            <ButtonGroup className="mb-2">
                                                <Button
                                                    type="submit"
                                                    theme="primary"
                                                >
                                                    Sửa
                                                </Button>
                                                &nbsp;&nbsp;&nbsp;
                                                <Button
                                                    onClick={() =>
                                                        history.goBack()
                                                    }
                                                    theme="secondary"
                                                >
                                                    Hủy
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col sm="8">
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-7 col-form-label">
                                            </Form.Label>
                                            <Col sm="5">
                                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '10px' }}>
                                                    {objectStatusFilter.map((option) => (
                                                        <button
                                                            type="button"
                                                            key={option.value}
                                                            onClick={() => handleButtonClick(option)}
                                                            style={{
                                                                padding: '10px',
                                                                backgroundColor: selectedOptions?.value == option.value ? 'blue' : 'white',
                                                                color: selectedOptions?.value == option.value ? 'white' : 'black',
                                                                border: '1px solid #ccc',
                                                                cursor: 'pointer',
                                                            }}
                                                        >
                                                            {option.label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Tiêu đề *
                                            </Form.Label>
                                            <Col sm="12">
                                                <Tabs>
                                                    <TabList>
                                                        <Tab>Tiếng việt</Tab>
                                                        <Tab>Tiếng Anh</Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <Form.Control
                                                            placeholder="Tên"
                                                            type="text"
                                                            name="title"
                                                            value={values.title}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.title}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.title}
                                                        </Form.Control.Feedback>
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Form.Control
                                                            placeholder="Tên"
                                                            type="text"
                                                            name="titleEn"
                                                            value={values.titleEn}
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.titleEn}
                                                        />
                                                    </TabPanel>
                                                </Tabs>

                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Danh mục *
                                            </Form.Label>
                                            <Col sm="12">
                                                <SelectTree
                                                    data={menuData}
                                                    showSearchBox
                                                    Placeholder="Danh mục"
                                                    positionExpand="right"
                                                    singleValueSelected={menuData.find(
                                                        (x) =>
                                                            x.id === values.categoryId
                                                    )}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'categoryId',
                                                            value.id
                                                        );
                                                    }}
                                                    isInvalid={!!errors.categoryId}
                                                />
                                                <div
                                                    style={{
                                                        display: 'block',
                                                        color: '#dc3545'
                                                    }}
                                                    className="invalid-feedback"
                                                >
                                                    {errors.categoryId}
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-form-label">
                                                Tóm tắt
                                            </Form.Label>
                                            <Col sm="12">
                                                <Tabs>
                                                    <TabList>
                                                        <Tab>Tiếng việt</Tab>
                                                        <Tab>Tiếng Anh</Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <Editor
                                                            name="description"
                                                            value={values.description}
                                                            onChange={(value) => {
                                                                setFieldValue(
                                                                    'description',
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Editor
                                                            name="descriptionEn"
                                                            value={values.descriptionEn}
                                                            onChange={(value) => {
                                                                setFieldValue(
                                                                    'descriptionEn',
                                                                    value
                                                                );
                                                            }}
                                                        />
                                                    </TabPanel>
                                                </Tabs>

                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-form-label">
                                                Nội dung
                                            </Form.Label>
                                            <Col sm="12">
                                                <Tabs>
                                                    <TabList>
                                                        <Tab>Tiếng việt</Tab>
                                                        <Tab>Tiếng Anh</Tab>
                                                    </TabList>
                                                    <TabPanel>
                                                        <Editor
                                                            name="content"
                                                            value={values.content}
                                                            onChange={async (value) => {
                                                                setFieldValue('content', value);
                                                                if (values.imagePath === "" || values.imageThumbnail === "") {
                                                                    var divContent = document.createElement('div');
                                                                    divContent.innerHTML = value;
                                                                    var imgs = divContent.querySelectorAll('img');
                                                                    if (imgs && imgs.length > 0) {
                                                                        for (let i = 0; i < imgs.length; i++) {
                                                                            if (imgs[i].getAttribute("src")) {
                                                                                console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                                                await setFieldValue(
                                                                                    'imagePath',
                                                                                    imgs[i].getAttribute("src"),
                                                                                    true
                                                                                );
                                                                                await setFieldValue(
                                                                                    'imageThumbnail',
                                                                                    imgs[i].getAttribute("src"),
                                                                                    true
                                                                                );
                                                                                break;
                                                                            }
                                                                        }
                                                                    }

                                                                }

                                                            }}
                                                        />
                                                    </TabPanel>
                                                    <TabPanel>
                                                        <Editor
                                                            name="contentEn"
                                                            value={values.contentEn}

                                                            onChange={async (value) => {
                                                                setFieldValue('contentEn', value);
                                                                if (values.imagePath === "" || values.imageThumbnail === "") {
                                                                    var divContent = document.createElement('div');
                                                                    divContent.innerHTML = value;
                                                                    var imgs = divContent.querySelectorAll('img');
                                                                    if (imgs && imgs.length > 0) {
                                                                        for (let i = 0; i < imgs.length; i++) {
                                                                            if (imgs[i].getAttribute("src")) {
                                                                                console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                                                await setFieldValue(
                                                                                    'imagePath',
                                                                                    imgs[i].getAttribute("src"),
                                                                                    true
                                                                                );
                                                                                await setFieldValue(
                                                                                    'imageThumbnail',
                                                                                    imgs[i].getAttribute("src"),
                                                                                    true
                                                                                );
                                                                                break;
                                                                            }
                                                                        }
                                                                    }

                                                                }

                                                            }}
                                                        />
                                                    </TabPanel>
                                                </Tabs>

                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className=" col-form-label">
                                                Từ khóa Seo
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Control
                                                    placeholder="Từ khóa"
                                                    type="text"
                                                    name="keywords"
                                                    value={values.keywords}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.keywords}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.keywords}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-form-label">
                                                Tiêu đề Seo
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Control
                                                    placeholder="Tiêu đề Og"
                                                    type="text"
                                                    name="ogTitle"
                                                    value={values.ogTitle}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.ogTitle}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.ogTitle}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-form-label">
                                                Mô tả Seo
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Control
                                                    placeholder="Mô tả Og"
                                                    type="text"
                                                    name="ogDescription"
                                                    value={values.ogDescription}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.ogDescription}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.ogDescription}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-form-label">
                                                Tag Seo
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Control
                                                    placeholder="Tag bài báo"
                                                    type="text"
                                                    name="articleTag"
                                                    value={values.articleTag}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.articleTag}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.articleTag}
                                                </Form.Control.Feedback>
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                    <Col sm="4">

                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-2 col-form-label">
                                                Ảnh đại diện *
                                            </Form.Label>
                                            <Col sm="12">
                                                <InputFile
                                                    enableReinitialize={true}
                                                    // values={values.imageThumbnail ? [BaseGatewayImage + values.imageThumbnail] : []}
                                                    values={values.imageThumbnail ? [isValidUrl(values.imageThumbnail) ? values.imageThumbnail : BaseGatewayImage + values.imageThumbnail] : []}
                                                    accept={acceptImage}
                                                    onChange={(files) => {
                                                        if (files.length > 0) {
                                                            setFieldValue(
                                                                'imagePath',
                                                                files[0].filePath,
                                                                true
                                                            );
                                                            setFieldValue(
                                                                'imageThumbnail',
                                                                files[0].thumbPath,
                                                                true
                                                            );
                                                        } else {
                                                            setFieldValue(
                                                                'imagePath',
                                                                '',
                                                                true
                                                            );
                                                            setFieldValue(
                                                                'imageThumbnail',
                                                                '',
                                                                true
                                                            );
                                                        }
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: 'block',
                                                        color: '#dc3545'
                                                    }}
                                                    className="invalid-feedback"
                                                >
                                                    {errors.imageThumbnail}
                                                </div>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-form-label">
                                                Thời gian xuất bản
                                            </Form.Label>
                                            <Col sm="12" className="icheck-primary">
                                                <DatePicker
                                                    selected={values.publishTime}
                                                    onChange={(date) => {
                                                        setFieldValue(
                                                            'publishTime',
                                                            date,
                                                            true
                                                        )
                                                    }
                                                    }
                                                    timeInputLabel="Time:"
                                                    dateFormat={DATE_TIME_REACT_FORMAT}
                                                    showTimeInput
                                                    className="form-control"
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Row>
                                            <Col sm="4">
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label className="col-sm-6 col-form-label">
                                                        Tiêu điểm
                                                    </Form.Label>
                                                    <Col sm="3" className="icheck-primary">
                                                        <Form.Control
                                                            id="checkboxPrimary1"
                                                            type="checkbox"
                                                            placeholder="Là tiêu điểm"
                                                            name="isFocus"
                                                            checked={values.isFocus}
                                                            onClick={handleChange}
                                                        />
                                                        <Form.Label htmlFor="checkboxPrimary1">
                                                            {' '}
                                                        </Form.Label>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                            <Col sm="4">
                                                <Form.Group as={Row} className="mb-3">
                                                    <Form.Label className="col-sm-6 col-form-label">
                                                        Nổi bật
                                                    </Form.Label>
                                                    <Col sm="3" className="icheck-primary">
                                                        <Form.Control
                                                            id="checkboxPrimary2"
                                                            type="checkbox"
                                                            placeholder="Nổi bật"
                                                            name="isHighlight"
                                                            checked={values.isHighlight}
                                                            onClick={handleChange}
                                                        />
                                                        <Form.Label htmlFor="checkboxPrimary2">
                                                            {' '}
                                                        </Form.Label>
                                                    </Col>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className=" col-form-label">
                                                Tác giả
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Control
                                                    placeholder="Tác giả"
                                                    type="text"
                                                    name="author"
                                                    value={values.author}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.author}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className=" col-form-label">
                                                Nguồn link
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Control
                                                    placeholder="Nguồn link"
                                                    type="text"
                                                    name="sourceLink"
                                                    value={values.sourceLink}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.sourceLink}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-form-label">
                                                Lượt view ảo
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Control
                                                    type="number"
                                                    name="virtualViews"
                                                    placeholder="Sắp xếp"
                                                    value={values.virtualViews}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-sm-4 col-form-label">
                                                Gắn Link Google Form
                                            </Form.Label>
                                            <Col sm="8" className="icheck-primary">
                                                <Form.Control
                                                    id="isGoogleForm"
                                                    type="checkbox"
                                                    placeholder=" Gắn Link Google Form"
                                                    name="isGoogleForm"
                                                    checked={values.isGoogleForm}
                                                    onClick={handleChange}
                                                />
                                                <Form.Label htmlFor="isGoogleForm">
                                                    {' '}
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className=" col-form-label">
                                                Nội dung Google Form
                                            </Form.Label>
                                            <Col sm="12">
                                                <Form.Control
                                                    placeholder="Nội dung Google Form"
                                                    type="text"
                                                    name="contentGoogleForm"
                                                    value={values.contentGoogleForm}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.contentGoogleForm}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-3">
                                            <Form.Label className="col-form-label">
                                                Tệp tin
                                            </Form.Label>
                                            <Col sm="12">
                                                <DocumentInput
                                                    isInvalid={!!errors.documentLink}
                                                    errorsValue={errors.documentLink}
                                                    value={values.documentLink}
                                                    onChangeProp={(value) => {
                                                        setFieldValue('documentLink', value);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 12, }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group>


                                {/* <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder="Tên"
                                                    type="text"
                                                    name="title"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.title}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {errors.title}
                                                </Form.Control.Feedback>
                                            </TabPanel>
                                            <TabPanel>
                                                <Form.Control
                                                    placeholder="Tên"
                                                    type="text"
                                                    name="titleEn"
                                                    value={values.titleEn}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.titleEn}
                                                />
                                            </TabPanel>
                                        </Tabs>

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Danh mục *
                                    </Form.Label>
                                    <Col sm="10">
                                        <SelectTree
                                            data={menuData}
                                            showSearchBox
                                            Placeholder="Danh mục"
                                            positionExpand="right"
                                            singleValueSelected={menuData.find(
                                                (x) =>
                                                    x.id === values.categoryId
                                            )}
                                            onChange={(value) => {
                                                setFieldValue(
                                                    'categoryId',
                                                    value.id
                                                );
                                            }}
                                            isInvalid={!!errors.categoryId}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.categoryId}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Trạng thái bài viết *
                                    </Form.Label>
                                    <Col sm="10">
                                        <Select
                                            name="postStatus"
                                            id="postStatus"
                                            placeholder="Chọn trạng thái bài viết"
                                            value={values.postStatus}
                                            onChange={(selectedOption) => {
                                                const event = {
                                                    target: {
                                                        name: 'postStatus',
                                                        value: selectedOption
                                                    }
                                                };
                                                handleChange(event);
                                            }}
                                            options={objectStatusFilter}
                                            styles={
                                                errors.postStatus !== undefined
                                                    ? styleSelectError
                                                    : styleSelectDefault
                                            }
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.postStatus}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tệp tin
                                    </Form.Label>
                                    <Col sm="10">
                                        <DocumentInput
                                            isInvalid={!!errors.documentLink}
                                            errorsValue={errors.documentLink}
                                            value={values.documentLink}
                                            onChangeProp={(value) => {
                                                setFieldValue('documentLink', value);
                                            }}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tóm tắt
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Editor
                                                    name="description"
                                                    value={values.description}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'description',
                                                            value
                                                        );
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Editor
                                                    name="descriptionEn"
                                                    value={values.descriptionEn}
                                                    onChange={(value) => {
                                                        setFieldValue(
                                                            'descriptionEn',
                                                            value
                                                        );
                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung
                                    </Form.Label>
                                    <Col sm="10">
                                        <Tabs>
                                            <TabList>
                                                <Tab>Tiếng việt</Tab>
                                                <Tab>Tiếng Anh</Tab>
                                            </TabList>
                                            <TabPanel>
                                                <Editor
                                                    name="content"
                                                    value={values.content}
                                                    onChange={(value) => {
                                                        setFieldValue('content', value);
                                                        if (values.imagePath === "" || values.imageThumbnail === "") {
                                                            var divContent = document.createElement('div');
                                                            divContent.innerHTML = value;
                                                            var imgs = divContent.querySelectorAll('img');
                                                            if (imgs && imgs.length > 0) {
                                                                for (let i = 0; i < imgs.length; i++) {
                                                                    if (imgs[i].getAttribute("src")) {
                                                                        console.log("element.getAttribute", imgs[i].getAttribute("src"));
                                                                        setFieldValue(
                                                                            'imagePath',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        setFieldValue(
                                                                            'imageThumbnail',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        return;
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }}
                                                />
                                            </TabPanel>
                                            <TabPanel>
                                                <Editor
                                                    name="contentEn"
                                                    value={values.contentEn}
                                                    onChange={(value) => {
                                                        setFieldValue('contentEn', value);
                                                        if (values.imagePath === "" || values.imageThumbnail === "") {
                                                            var divContent = document.createElement('div');
                                                            divContent.innerHTML = value;
                                                            var imgs = divContent.querySelectorAll('img');
                                                            if (imgs && imgs.length > 0) {
                                                                for (let i = 0; i < imgs.length; i++) {
                                                                    if (imgs[i].getAttribute("src")) {
                                                                        setFieldValue(
                                                                            'imagePath',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        setFieldValue(
                                                                            'imageThumbnail',
                                                                            imgs[i].getAttribute("src"),
                                                                            true
                                                                        );
                                                                        return;
                                                                    }
                                                                }
                                                            }

                                                        }
                                                    }}
                                                />
                                            </TabPanel>
                                        </Tabs>

                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Gắn Link Google Form
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="isGoogleForm"
                                            type="checkbox"
                                            placeholder=" Gắn Link Google Form"
                                            name="isGoogleForm"
                                            checked={values.isGoogleForm}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="isGoogleForm">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nội dung Google Form
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Nội dung Google Form"
                                            type="text"
                                            name="contentGoogleForm"
                                            value={values.contentGoogleForm}
                                            onChange={handleChange}
                                            isInvalid={!!errors.contentGoogleForm}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Ảnh đại diện *
                                    </Form.Label>
                                    <Col sm="10">
                                        <InputFile
                                            enableReinitialize={true}
                                            // values={values.imageThumbnail ? [BaseGatewayImage + values.imageThumbnail] : []}
                                            values={values.imageThumbnail ? [isValidUrl(values.imageThumbnail) ? values.imageThumbnail : BaseGatewayImage + values.imageThumbnail] : []}
                                            accept={acceptImage}
                                            onChange={(files) => {
                                                if (files.length > 0) {
                                                    setFieldValue(
                                                        'imagePath',
                                                        files[0].filePath,
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'imageThumbnail',
                                                        files[0].thumbPath,
                                                        true
                                                    );
                                                } else {
                                                    setFieldValue(
                                                        'imagePath',
                                                        '',
                                                        true
                                                    );
                                                    setFieldValue(
                                                        'imageThumbnail',
                                                        '',
                                                        true
                                                    );
                                                }
                                            }}
                                        />
                                        <div
                                            style={{
                                                display: 'block',
                                                color: '#dc3545'
                                            }}
                                            className="invalid-feedback"
                                        >
                                            {errors.imageThumbnail}
                                        </div>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Thời gian xuất bản
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <DatePicker
                                            selected={
                                                values.publishTime
                                                    ? values.publishTime
                                                    : new Date()
                                            }
                                            onChange={(date) => {
                                                setFieldValue(
                                                    'publishTime',
                                                    date,
                                                    true
                                                );
                                            }}
                                            timeInputLabel="Time:"
                                            dateFormat={DATE_TIME_REACT_FORMAT}
                                            showTimeInput
                                            className="form-control"
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tác giả
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tác giả"
                                            type="text"
                                            name="author"
                                            value={values.author}
                                            onChange={handleChange}
                                            isInvalid={!!errors.author}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nguồn link
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Nguồn link"
                                            type="text"
                                            name="sourceLink"
                                            value={values.sourceLink}
                                            onChange={handleChange}
                                            isInvalid={!!errors.sourceLink}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu điểm
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary1"
                                            type="checkbox"
                                            placeholder="Tiêu điểm"
                                            name="isFocus"
                                            checked={values.isFocus}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary1">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Nổi bật
                                    </Form.Label>
                                    <Col sm="10" className="icheck-primary">
                                        <Form.Control
                                            id="checkboxPrimary2"
                                            type="checkbox"
                                            placeholder="Nổi bật"
                                            name="isHighlight"
                                            checked={values.isHighlight}
                                            onClick={handleChange}
                                        />
                                        <Form.Label htmlFor="checkboxPrimary2">
                                            {' '}
                                        </Form.Label>
                                    </Col>
                                </Form.Group>


                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Lượt view ảo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            type="number"
                                            name="virtualViews"
                                            placeholder="Sắp xếp"
                                            value={values.virtualViews}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Từ khóa Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Từ khóa"
                                            type="text"
                                            name="keywords"
                                            value={values.keywords}
                                            onChange={handleChange}
                                            isInvalid={!!errors.keywords}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.keywords}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tiêu đề Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tiêu đề Og"
                                            type="text"
                                            name="ogTitle"
                                            value={values.ogTitle}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogTitle}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogTitle}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Mô tả Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Mô tả Og"
                                            type="text"
                                            name="ogDescription"
                                            value={values.ogDescription}
                                            onChange={handleChange}
                                            isInvalid={!!errors.ogDescription}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ogDescription}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Form.Label className="col-sm-2 col-form-label">
                                        Tag Seo
                                    </Form.Label>
                                    <Col sm="10">
                                        <Form.Control
                                            placeholder="Tag bài báo"
                                            type="text"
                                            name="articleTag"
                                            value={values.articleTag}
                                            onChange={handleChange}
                                            isInvalid={!!errors.articleTag}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.articleTag}
                                        </Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row} className="mb-3">
                                    <Col md={{ span: 10, offset: 2 }}>
                                        <ButtonGroup className="mb-2">
                                            <Button
                                                type="submit"
                                                theme="primary"
                                            >
                                                Sửa
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                onClick={() =>
                                                    history.goBack()
                                                }
                                                theme="secondary"
                                            >
                                                Hủy
                                            </Button>
                                        </ButtonGroup>
                                    </Col>
                                </Form.Group> */}
                            </Form>
                        )}
                    </Formik>
                )}
                <ReactSpinner loadings={isLoadingEdit} />
            </Card.Body>
        </Card>
    );
};

export default PostEdit;
